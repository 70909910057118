import { SET_ALERT } from "../Alerts/constants";
import {
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE,
  GET_TICKETS_REQUEST,
  DELETE_TICKETS_FAILURE,
  DELETE_TICKETS_REQUEST,
  DELETE_TICKETS_SUCCESS,
  POST_TICKETS_REQUEST,
  POST_TICKETS_FAILURE,
  POST_TICKETS_SUCCESS,
  PUT_TICKETS_REQUEST,
  PUT_TICKETS_FAILURE,
  PUT_TICKETS_SUCCESS,
  GET_SINGLE_TICKETS_REQUEST,
  GET_SINGLE_TICKETS_SUCCESS,
  GET_SINGLE_TICKETS_FAILURE,
  GET_TICKETS_FROM_COMPANY_REQUEST,
  GET_TICKETS_FROM_COMPANY_SUCCESS,
  GET_TICKETS_FROM_COMPANY_FAILURE,
} from "./constants";
import api from "app/services/config";

export const getTickets =
  (order, orderBy, status, page, searchKey) => async (dispatch, getState) => {
    dispatch({ type: GET_TICKETS_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/tickets`, {
        params: {
          orderBy: `${search.data.orderBy},${search.data.order}`,
          page: search.data.page,
          ticket_status_id: search.data.status,
          ticket_category_id: search.data.category,
          s: searchKey,
          company_id: search.data.company_id,
          responsible_user_id: search.data.user_id,
          product_family_id: search.data.product_family_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_TICKETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_FAILURE,
        payload: error.message,
      });
    }
  };

export const getSingleTicket = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SINGLE_TICKETS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.get(`/tickets/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: GET_SINGLE_TICKETS_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_TICKETS_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteTickets = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_TICKETS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/tickets/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_TICKETS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_TICKETS_FAILURE,
      payload: error.message,
    });
  } finally {
    dispatch(getTickets("asc", "id"));
  }
};
export const createTickets = (data) => async (dispatch, getState) => {
  dispatch({ type: POST_TICKETS_REQUEST });
  try {
    const { auth } = getState();
    const response = await api.post(
      `/tickets`,
      {
        title: data.title,
        description: data.description,
        ticket_channel_id: 3,
        ticket_category_id: data.ticket_category_id,
        ticket_status_id: data.ticket_status_id,
        responsible_user_id: data.responsible_user_id,
        user_id: data.user_id,
        cc_user_ids: data.cc,
        ticket_response_is_public: data.ticket_response_is_public,
        product_family_id: data.product_family_id,
        unit_id: data.unit_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: POST_TICKETS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Ticket criado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: POST_TICKETS_FAILURE,
      payload: error.message,
    });
  } finally {
    dispatch(getTickets("asc", "id"));
  }
};

export const editTickets = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PUT_TICKETS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.put(
      `/tickets/${id}`,
      {
        title: data.title,
        description: data.description,
        ticket_category_id: data.ticket_category_id,
        ticket_status_id: data.ticket_status_id,
        responsible_user_id: data.responsible_user_id,
        user_id: data.user_id,
        cc_user_ids: data.cc,
        product_family_id: data.product_family_id,
        unit_id: data.unit_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    dispatch({
      type: PUT_TICKETS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Ticket alterado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: PUT_TICKETS_FAILURE,
      payload: error.message,
    });
  } finally {
    dispatch(getTickets("asc", "id"));
  }
};
