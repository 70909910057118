import { asyncCompaniesActions, asyncCustomersActions, asyncProductFamiliesActions, asyncTicketsActions, asyncTicketsCategoriesActions, asyncTicketsResponsesActions, asyncUnitsActions, asyncUserSacActions } from "app/redux/reducers";
import { SET_ALERT } from "../../Alerts/constants";
import {
  START_NEW_TICKET_FAILURE,
  START_NEW_TICKET_REQUEST,
  START_NEW_TICKET_SUCCESS,
  GET_SINGLE_TICKETS_DETAIL_REQUEST,
  GET_SINGLE_TICKETS_DETAIL_FAILURE,
  GET_SINGLE_TICKETS_DETAIL_SUCCESS,
} from "./constants";
import api from "app/services/config";

export const startNewTicket =
  () => async (dispatch, getState) => {
    dispatch({ type: START_NEW_TICKET_REQUEST });

    try {
      const {  sac, ticketsCategories, companies, productFamilies}  = getState();

      const fetchData = [ 
        dispatch(asyncUserSacActions.get("name","desc",1,1,"",true,"1,4")),
        dispatch(asyncTicketsCategoriesActions.get("name","desc",1,1,"",true)),
        dispatch(asyncCompaniesActions.getCompanies("name","desc",1,1,"",true)),
        dispatch(asyncProductFamiliesActions.getProductFamilies("name","desc",1,1,"",true))
      ]

      await Promise.all(fetchData).then(() => {
        dispatch({
          type: START_NEW_TICKET_SUCCESS,
          payload: {
            sac: sac,
            ticketCategories: ticketsCategories,
            companies: companies,
            productFamilies: productFamilies
          },
        });
      })

 
    } catch (error) {
      dispatch({
        type: START_NEW_TICKET_FAILURE,
        payload: error.message,
      });
    }
  };

  export const getTicketDetails =
  (id) => async (dispatch, getState) => {
    dispatch({ type: GET_SINGLE_TICKETS_DETAIL_REQUEST });

    try {
      const {  ticketsResponses, customers, sac, auth, ticketsCategories, companies, productFamilies, units}  = getState();

      const fetchData = [ 
        dispatch(asyncUserSacActions.get("name","desc",1,1,"",true,"1,4")),
        dispatch(asyncTicketsCategoriesActions.get("name","desc",1,1,"",true)),
        dispatch(asyncCompaniesActions.getCompanies("name","desc",1,1,"",true)),
        dispatch(asyncProductFamiliesActions.getProductFamilies("name","desc",1,1,"",true)),
        dispatch(asyncTicketsResponsesActions.get(id))
      ]
      const ticketDetails = await api.get(`/tickets/${id}`, {      
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
    

      await Promise.all(fetchData).then(() => {
        dispatch(asyncCustomersActions.getCustomers("name","desc",1,1,"",true,ticketDetails.data.responsible_user.profile.unit.id)),
        dispatch(asyncUnitsActions.getUnitsFromCompany(ticketDetails.data.responsible_user.profile.unit.company.id))
      })

      dispatch({
        type: GET_SINGLE_TICKETS_DETAIL_SUCCESS,
        payload: {
          sac: sac,
          ticketCategories: ticketsCategories,
          companies: companies,
          productFamilies: productFamilies,
          units: units,
          ticketsResponses: ticketsResponses,
          customers: customers,
          tickets: ticketDetails
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_TICKETS_DETAIL_FAILURE,
        payload: error.message,
      });
    }
  };

