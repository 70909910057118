import { createAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const serviceOrderFieldsSlice = createApi({
  reducerPath: "serviceOrderFieldsSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchPivots: builder.query({
        query() {
          return "pivots/?noPaginate=true";
        },
        providesTags: ["Pivots"],
        invalidatesTags: ["Reducers"],
      }),
      fetchReducers: builder.query({
        query(pivot_id) {
          return `pivots/reducers/?pivot_id=${pivot_id}&noPaginate=true`;
        },
      }),
      providesTags: ["Reducers"],
    };
  },
});

export const { useFetchPivotsQuery, useFetchReducersQuery } =
  serviceOrderFieldsSlice;
