import React from "react";

import { Navigate } from "react-router-dom";

import ChamadosTecnicos from "app/pages/ChamadosTecnicos/ChamadosTecnicos";
import DetalhesChamados from "app/pages/DetalhesChamados/DetalhesChamados";
import Login1 from "app/pages/auth-pages/login1/Login1";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import Signup1 from "app/pages/auth-pages/signup1/Signup1";
import NovoChamado from "app/pages/NovoChamado/NovoChamado";
import Familias from "app/pages/Produtos/Familias";
import ProductModels from "app/pages/Produtos/ProductModels";
import Product from "app/pages/Produtos/Product";
import Customers from "app/pages/Users/Costumers";
import Companies from "app/pages/Companies/Companies";
import Units from "app/pages/Companies/Units";
import Agenda from "app/pages/Agenda";
import Parts from "app/pages/Produtos/Parts";
import ProblemTypes from "app/pages/Setup/ProblemTypes";
import ResolutionCategories from "app/pages/Setup/ResolutionCategories";
import Technicians from "app/pages/Users/Technicians";
import Administrators from "app/pages/Users/Administrators";
import Sac from "app/pages/Users/Sac";
import TaskTypes from "app/pages/Setup/TaskTypes";
import Tickets from "app/pages/Tickets";
import TicketDetails from "app/pages/Tickets/TicketDetails";
import Macros from "app/pages/Setup/Macros";

import CompanyDetails from "app/pages/Companies/Companies/companyDetails";
import TicketsCategories from "app/pages/Setup/TicketsCategories";
import Issues from "app/pages/Produtos/Issues";
import Solutions from "app/pages/Produtos/Solutions";
import Reseller from "app/pages/Users/Resellers";
import Readonly from "app/pages/Users/Readonly";
import UserDetails from "app/pages/Users/UserDetails";
import FamilySolutions from "app/pages/Produtos/FamilySolutions";
import FamilyIssues from "app/pages/Produtos/FamilyIssues";
import NewSo from "app/pages/ServiceOrders/NewSo";
import ReopeningSo from "app/pages/ServiceOrders/ReopeningSo";
import EditSo from "app/pages/ServiceOrders/EditSo";
import Pivo from "app/pages/Pivos";
import PivoDetails from "app/pages/Pivos/Pivots/PivoDetails";
import PivoDashboard from "app/pages/Pivos/Pivots/PivoDashboard";
import PivotHistory from "app/pages/Pivos/PivotHistory";
const routes = [
  {
    path: "/",
    element: <Navigate to={"/login"} />,
    exact: true,
  },
  {
    path: "/login",
    element: <Login1 />,
  },
  {
    path: "/recupera-senha",
    element: <ForgotPassword />,
  },
  {
    path: "/cadastro",
    element: <Signup1 />,
  },
  {
    path: "/os",
    element: <ChamadosTecnicos />,
    exact: true,
  },
  {
    path: "/os/:statusOs",
    element: <ChamadosTecnicos />,
    exact: true,
  },
  {
    path: "/os/detalhes/:id",
    element: <DetalhesChamados />,
    exact: true,
  },
  {
    path: "/os/reabrir/:id",
    element: <ReopeningSo />,
    exact: true,
  },
  {
    path: "/os/novo",
    element: <NewSo />,
    exact: true,
  },
  {
    path: "/os/editar/:id",
    element: <EditSo />,
    exact: true,
  },
  {
    path: "/produtos/familias",
    element: <Familias />,
    exact: true,
  },
  {
    path: "/produtos/product",
    element: <Product />,
    exact: true,
  },
  {
    path: "/produtos/pecas",
    element: <Parts />,
    exact: true,
  },
  {
    path: "/produtos/problemas",
    element: <Issues />,
    exact: true,
  },
  {
    path: "/produtos/solucoes",
    element: <Solutions />,
    exact: true,
  },
  {
    path: "/produtos/modelos",
    element: <ProductModels />,
    exact: true,
  },
  {
    path: "/usuarios/clientes",
    element: <Customers />,
    exact: true,
  },
  {
    path: "/empresas",
    element: <Companies />,
    exact: true,
  },
  {
    path: "/empresas/detalhes/:id",
    element: <CompanyDetails />,
    exact: true,
  },
  {
    path: "/empresas/unidades",
    element: <Units />,
    exact: true,
  },
  {
    path: "/agenda",
    element: <Agenda />,
    exact: true,
  },
  {
    path: "/configuracoes/tipos-de-problemas",
    element: <ProblemTypes />,
    exact: true,
  },
  {
    path: "/configuracoes/categorias-de-resolucao",
    element: <ResolutionCategories />,
    exact: true,
  },
  {
    path: "/configuracoes/tipos-de-atividades",
    element: <TaskTypes />,
    exact: true,
  },
  {
    path: "/configuracoes/macros",
    element: <Macros />,
    exact: true,
  },
  {
    path: "/configuracoes/categorias-de-tickets",
    element: <TicketsCategories />,
    exact: true,
  },
  {
    path: "/produtos/solucoes-de-familias",
    element: <FamilySolutions />,
    exact: true,
  },
  {
    path: "/produtos/ocorrencias-de-familias",
    element: <FamilyIssues />,
    exact: true,
  },
  {
    path: "/usuarios/tecnicos",
    element: <Technicians />,
    exact: true,
  },
  {
    path: "/pivos",
    element: <Pivo />,
    exact: true,
  },
  {
    path: "/pivos/detalhes/:id/redutores",
    element: <PivoDetails />,
    exact: true,
  },
  {
    path: "/pivos/detalhes/:id/dashboard",
    element: <PivoDashboard />,
    exact: true,
  },
  {
    path: "/pivos/detalhes/:id/historico",
    element: <PivotHistory />,
    exact: true,
  },

  {
    path: "/usuarios/administradores",
    element: <Administrators />,
    exact: true,
  },
  {
    path: "/usuarios/revendas",
    element: <Reseller />,
    exact: true,
  },
  {
    path: "/usuarios/sac",
    element: <Sac />,
    exact: true,
  },
  {
    path: "/usuarios/somente-leitura",
    element: <Readonly />,
    exact: true,
  },
  {
    path: "/usuarios/:id/detalhes",
    element: <UserDetails />,
    exact: true,
  },
  {
    path: "/tickets",
    element: <Tickets />,
    exact: true,
  },
  {
    path: "/tickets/:statusTicket",
    element: <Tickets />,
    exact: true,
  },
  {
    path: "/tickets/responder/:id",
    element: <TicketDetails />,
    exact: true,
  },
  {
    path: "/tickets/novo",
    element: <TicketDetails />,
    exact: true,
  },
  {
    path: "/tickets/empresa/:companyId/:statusTicket",
    element: <Tickets />,
    exact: true,
  },
  {
    path: "/tickets/cliente/:userId/:statusTicket",
    element: <Tickets />,
    exact: true,
  },

  /* ...dashboardRoutes,
    ...appsRoutes,
    ...widgetsRoutes,
    ...metricsRoutes,
    ...muiRoutes,
    ...extensionsRoutes,
    ...calendarRoutes,
    ...chartRoutes,
    ...mapRoutes,
    ...authRoutes,
    ...extraRoutes,
    ...userRoutes,
    ...listViewRoutes,
    ...gridViewRoutes, */
];

export default routes;
