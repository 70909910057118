import React from 'react';
import {useDropzone} from "react-dropzone";
import {Typography} from "@mui/material";
import DndWrapper from "./DndWrapper";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import { fromImage } from 'imtool';
import Compressor from 'compressorjs';
import { appTheme } from 'app/themes/app/theme';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 300,
    height: 260,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%',
};

const DzPreviews = ({ selectFiles, image, video }) => {

    const [files, setFiles] = React.useState([]);
    const [compress, setCompress] = React.useState(null);
    const [compressedImages, setCompressedImages] = React.useState([]);

    React.useEffect(() => {
        console.log("COMPRESS IMAGE TO INSERT > ", compress);
        if(compress) {
            setCompressedImages(prev => [...prev, compress]);
        }
    }, [compress]);

    React.useEffect(() => {
        selectFiles(compressedImages);
    }, [compressedImages]);

    React.useEffect(() => {
        setCompressedImages([]);
        files.forEach(async file => {
            console.log(file.type);

            if(file.type.includes("video")) {
                setCompressedImages(prev => [...prev, file]);
            } else {
                new Compressor(file, {
                    quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                    maxHeight: 600,
                    maxWidth: 600,     
                    success: (compressedResult) => {
                        console.log("RESULT COMPRESS ", compressedResult);
                        const blobToFile = new File(
                            [compressedResult],
                            compressedResult.name,
                            {
                                type: compressedResult.type,
                                lastModified: compressedResult.lastModified
                            }
                        );

                        console.log("BLOB TO FILE > ", blobToFile);

                        setCompress(blobToFile);
                    },
                });
            }
        });

        setCompress(null);
    }, [files]);

    const {getRootProps, getInputProps, isDragAccept, isDragActive} = useDropzone({
        accept: image ? ['image/*'] : ['video/*'],
        onDrop: acceptedFiles => {
            setFiles(
                prev => [
                    ...prev,
                    ...acceptedFiles.map(file =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        }),
                    ),
                ]
            );
        },
    });

    const thumbs = files.map((file, index) => (
            <div
                style={{
                    ...thumb,
                    cursor: "pointer",
                }}
                key={file.name}
                onClick={() => {
                    const res = window.confirm("Deseja remover essa mídia?");
                    if(res) {
                        setFiles(prev => prev.filter((_, i) => i != index));
                    }
                }}
            >
                <div style={thumbInner}>
                    {
                        file.type.includes("image") ? <img src={file.preview} style={img} alt=""/>
                        : <video src={file.preview} controls style={img} alt="" />
                    }
                </div>
            </div>
        ));

    React.useEffect(
        () => () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    const dropzoneCallbackConfig = {
        accept: {
            borderColor: appTheme.palette.green200,
            color: appTheme.palette.green100,
            text: "Solte os arquivos aqui"
        },
        reject: {
            borderColor: appTheme.palette.red200,
            color: appTheme.palette.red100,
            text: "Tipo de arquivo não suportado"
        }
    } 

    return (
        <Div sx={{ flex: 1}}>
            <DndWrapper
                sx={{
                    borderColor: isDragActive ? dropzoneCallbackConfig[isDragAccept ? "accept" : "reject"].borderColor : "#BBB",
                    color: isDragActive ? dropzoneCallbackConfig[isDragAccept ? "accept" : "reject"].color : "inherit"
                }}
            >
                <div {...getRootProps({className: 'dropzone', style: { display: "flex", flex: 1, width: "100%", alignItems: "center", justifyContent: "center" }})}>
                    <input {...getInputProps()} />
                    <Typography sx={{ cursor: "pointer" }} variant={"body1"}>
                        {
                            isDragActive
                                ? dropzoneCallbackConfig[isDragAccept ? "accept" : "reject"].text
                                : `Clique ou solte ${image ? "as imagens" : "os vídeos"} aqui!`
                        }
                    </Typography>
                </div>
            </DndWrapper>
            <aside style={thumbsContainer}>{thumbs}</aside>
        </Div>
    );
};

export default DzPreviews;
