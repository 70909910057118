import moment from "moment";
import { config } from "../config/main";
import { Avatar, Typography } from "@mui/material";
import { appTheme } from "app/themes/app/theme";
import Div from "@jumbo/shared/Div";
import * as Yup from "yup";
import api from "app/services/config";
import { useSelector } from "react-redux";

export const getAssetPath = (url, size) => {
  if (config.useImagePlaceHolder) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const normalizeDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const firstLetter = (word) => {
  return (
    <Div
      sx={{
        mr: { xs: 0, md: 2 },
        width: { xs: 30, md: 45 },
        height: { xs: 30, md: 45 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: appTheme.palette.red100,
        borderRadius: "50px",
        p: (theme) => theme.spacing(1, 2),
        px: 1,
        mb: { xs: 2, md: 0 },
      }}
    >
      <Typography variant={"h6"} color={"#fff"} mb={0}>
        {word ? word.charAt(0).toUpperCase() : "N/A"}
      </Typography>
    </Div>
  );
};

export const renderAvatar = (uri) => {
  const img = `${process.env.REACT_APP_PHOTO_PATH}${uri}`;

  return <Avatar src={img} sx={{ width: 55, height: 55 }} />;
};

export const formatDate = (day, hour) => {
  if (!day || !hour) return null;

  const format = day
    .hour(hour.split(":")[0])
    .minute(0)
    .format("YYYY-MM-DD HH:mm:00");

  return format;
};

export const normalizePhone = (phone) => {
  if (phone.length === 11) {
    phone = phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
  }

  if (phone.length === 10) {
    phone = phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  return phone;
};

export const statusIdToName = (status_id) => {
  const status = [
    "Aberto",
    "A caminho",
    "Chegou",
    "Resolvido",
    "Pendente",
    "Cancelado",
  ];

  return status[status_id - 1];
};
export const formatDate2 = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
