import {
  GET_TICKETS_RESPONSES_SUCCESS,
  GET_TICKETS_RESPONSES_FAILURE,
  GET_TICKETS_RESPONSES_REQUEST,
 
  POST_TICKETS_RESPONSES_REQUEST,
  POST_TICKETS_RESPONSES_FAILURE,
  POST_TICKETS_RESPONSES_SUCCESS,

} from "./constants";
import api from "app/services/config";

export const getTicketsResponses =
  (id) => async (dispatch, getState) => {
    dispatch({ type: GET_TICKETS_RESPONSES_REQUEST });

    try {
      const { auth, search } = getState();
      const response = await api.get(`/tickets/responses`, {
        params: {
          ticket_id: id,
          orderBy: 'id,desc',
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      dispatch({
        type: GET_TICKETS_RESPONSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_RESPONSES_FAILURE,
        payload: error.message,
      });
    }
  };

export const postTicketsResponse =
  (data) => async (dispatch, getState) => {
    dispatch({ type: POST_TICKETS_RESPONSES_REQUEST });

    try {
      const { auth } = getState();
      const response = await api.post(
        `/tickets/responses`,
        {
          response: data.response,
          ticket_id: data.ticket_id,
          ticket_channel_id: 3,
          is_public: data.is_public,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      dispatch({
        type: POST_TICKETS_RESPONSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: POST_TICKETS_RESPONSES_FAILURE,
        payload: error.message,
      });
    } finally {
      dispatch(getTicketsResponses());
    }
  };
