import { asyncUploadPhotoActions } from "app/redux/reducers";
import {
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  PUT_USERS_FAILURE,
  PUT_USERS_REQUEST,
  PUT_USERS_SUCCESS,
} from "./constants";
import api from "app/services/config";
import { SET_ALERT } from "../../Alerts/constants";
import { instanceOf } from "prop-types";
import { normalizeDate, normalizePhone } from "app/utils/appHelpers";

export const deleteUser = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_USERS_REQUEST });

  try {
    const { auth } = getState();
    const response = await api.delete(`/users/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    dispatch({
      type: DELETE_USERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Deletado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: DELETE_USERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao deletar", variant: "error" },
    });
  }
};

export const editUser = (data, id) => async (dispatch, getState) => {
  dispatch({ type: PUT_USERS_REQUEST });

  data.phone = normalizePhone(data.phone);

  try {
    const { auth } = getState();
    const response = await api.put(`/users/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    if (data.avatar instanceof File) {
      await dispatch(asyncUploadPhotoActions.post(data.avatar, id));
    }
    dispatch({
      type: PUT_USERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Editado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: PUT_USERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao editar", variant: "error" },
    });
  }
};
export const createUser = (data, id) => async (dispatch, getState) => {
  dispatch({ type: CREATE_USERS_REQUEST });

  data.phone = normalizePhone(data.phone);
  try {
    const { auth } = getState();
    const response = await api.put(`/users/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    });

    if (data.avatar instanceof File) {
      await dispatch(asyncUploadPhotoActions.post(data.avatar, id));
    }
    dispatch({
      type: CREATE_USERS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Criado com sucesso", variant: "success" },
    });
  } catch (error) {
    dispatch({
      type: CREATE_USERS_FAILURE,
      payload: error.message,
    });
    dispatch({
      type: SET_ALERT,
      payload: { message: "Erro ao criar", variant: "error" },
    });
  }
};
