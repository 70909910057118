import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "app/services/config";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../../components/CustomInputs/constants";



const initialState = {
    productFamilyIssues: [],  
    status_product_family_issue: IDLE, //'idle' | 'loading' | 'succeeded' | 'failed'
    error_product_family_issue: null,
    loading_product_family_issue: false
}

  export const getProductFamilyIssues = createAsyncThunk(
    'serviceOrder/fetchProductFamilyIssues',
    async (product_family_id, { getState }) => {
        //para reaproveitar o modelo antigo de thunk adicionei variaveis vazias para deixar compatível
      const { auth } = getState();
      const response = await api.get(`/product-families/issues`, {
        params: {
            product_family_id: product_family_id  
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      return response.data
    }
  );



const serviceOrdersSlice = createSlice({
    name: 'serviceOrders',
    initialState,
    reducers: {  
        clearProductFamilyIssues: {
            reducer(state) {
                console.log('limpando')
                state.productFamilyIssues = []
            }
        } 
    },
    extraReducers(builder) {
        builder
        .addCase(getProductFamilyIssues.pending, (state) => {
            state.status_product_family_issue = LOADING;
            state.error_product_family_issue = null;

        })
        .addCase(getProductFamilyIssues.fulfilled, (state, action) => {
            state.status_product_family_issue = SUCCEEDED;
            state.productFamilyIssues = action.payload;
            state.error_product_family_issue = null;
        })
        .addCase(getProductFamilyIssues.rejected, (state, action) => {
            state.status_product_family_issue = FAILED;
            state.error_product_family_issue = action.error.message;
        })


    }
})

export const selectProductFamilyIssues = (state) => state.serviceOrders.productFamilyIssues;

export const getIssueStatus = (state) => state.serviceOrders.status_product_family_issue;
export const getIssueError = (state) => state.serviceOrders.error_product_family_issue;
export const getIssueLoading = (state) => state.serviceOrders.loading_product_family_issue;

export const { clearProductFamilyIssues } = serviceOrdersSlice.actions

export default serviceOrdersSlice.reducer