import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent/JumboContent";
import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  Button,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import OsTypeField from "./components/OsTypeField/OsTypeField";

import React, { useEffect, useState } from "react";
import DzPreviews from "./components/DzPreviews";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import SimpleMap from "./components/SimpleMap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { cleanSearch } from "app/redux/modules/Search/actions";
import { getResales } from "app/redux/modules/Customers/Companies/actions";
import { CLEAN_COMPANIES_STORE } from "app/redux/modules/Customers/Companies/constants";
import { getCustomers } from "app/redux/modules/Users/Customers/actions";
import { CLEAN_CUSTOMERS_STORE } from "app/redux/modules/Users/Customers/constants";
import { getUnitsFromCompany } from "app/redux/modules/Customers/Units/actions";
import { getProduct } from "app/redux/modules/Products/product/actions";
import { getProductModels } from "app/redux/modules/Products/productModel/actions";
import { CLEAN_PRODUCT_FAMILIES_STORE } from "app/redux/modules/Products/families/constants";
import { CLEAN_ISSUES_STORE } from "app/redux/modules/Products/issues/constants";
import { CLEAN_PRODUCT_STORE } from "app/redux/modules/Products/product/constants";
import { CLEAN_PRODUCT_MODEL_STORE } from "app/redux/modules/Products/productModel/constants";
import {
  asyncCompaniesActions,
  asyncCustomersActions,
  asyncFamilyIssuesActions,
  asyncProductActions,
  asyncProductFamiliesActions,
  asyncProductModelActions,
  asyncServiceOrdersActions,
  asyncTaskTypesActions,
  asyncUnitsActions,
  asyncUserTechniciansActions,
} from "app/redux/reducers";
import { CLEAN_UNITS_STORE } from "app/redux/modules/Customers/Units/constants";
import { CLEAN_SERVICE_ORDERS_STORE } from "app/redux/modules/ServiceOrders/constants";
import Lottie from "lottie-react";
import locationAnimation from "assets/animations/locationAnimation.json";
import dayjs from "dayjs";

import {
  MULTIPLE,
  RESELER_THUNK,
  SINGLE,
} from "../../components/CustomInputs/constants";
import AutoCompleteInput from "../../components/CustomInputs/AutoCompleteInput";
import { CLEAN_FAMILY_ISSUES_STORE } from "app/redux/modules/Products/FamilyIssues/constants";
import { selectAnFamilyIssue } from "app/redux/modules/Products/FamilyIssues/actions";
import MenuLocationSelectInput from "app/components/CustomInputs/MenuLocationSelectInput";
import MultiSelectInput from "app/components/CustomInputs/MultiSelectInput";
import { CLEAN_TECHNICIAN_STORE } from "app/redux/modules/Users/Technicians/constants";
import { ServiceOrdersSkeleton } from "./Skeleton";
import { useNavigate } from "react-router-dom";
import { selectTechnician } from "app/redux/modules/Users/Technicians/actions";
import {
  KREBSFER,
  ORIGIN_SELECT_DATA,
  osTypes,
  ROLE_REVENDA,
} from "./untils/constants";
import MenuSelectInput from "app/components/CustomInputs/MenuSelectInput";
import { formatDate } from "app/utils/appHelpers";
import { freeTimes } from "./untils/constants";
import { editSoValidationSchema } from "./formValidation";
import PivotField from "./components/PivotField/PivotField";
import ReducerField from "./components/ReducerField/ReducerField";
import { setSelectedReducers } from "./features/so-slice";
export default function EditSo() {
  const dispatch = useDispatch();
  const {
    companies: { data, resales },
    units,
    customers,
    productFamilies,
    product,
    productModels,
    taskTypes,
    serviceOrders,
    companies,
    familyIssues,
    technicians,
  } = useSelector((state) => state);

  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const { id } = useParams();

  const [showImagePicker, setShowImagePicker] = useState(false);
  const [images, setImages] = useState([]);
  const [showVideoPicker, setShowVideoPicker] = useState(false);
  const [videos, setVideos] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [propertyLocation, setPropertyLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFreeTimes, setShowFreeTimes] = useState(false);

  const [show, setShow] = useState(false);
  const [changeTechnicians, setChangeTechnicians] = useState(false);

  const [previewData, setPreviewData] = useState({
    company: "",
    property: "",
    client: "",
    family: "",
    product: "",
    model: "",
    issue: "",
    description: "",
    internal_observations: "",
    invoice: "",
    serial_number: "",
    service_type: "",
    responsibles: "",
    scheduled_at: ["", ""],
    endAt: ["", ""],
  });

  const handleSubmit = async (dt, { resetForm }) => {
    setLoading(true);

    const files = [];

    for (let i = 0; i < images.length; i++) {
      files.push({
        file: images[i],
        description: `Imagem atendimento ${i + 1}`,
      });
    }

    for (let i = 0; i < videos.length; i++) {
      files.push({
        file: videos[i],
        description: `Video atendimento ${i + 1}`,
      });
    }

    const serviceData = {
      product_family_issues_ids: dt.issue,
      description: dt.description,
      invoice: dt.invoice,
      serial_number: dt.serial_number,
      service_type: dt.service_type,
      responsible_user_id: dt.client,
      latitude: propertyLocation.lat,
      longitude: propertyLocation.lng,
      internal_observations: dt.internal_observations,
      orders_type_id: 1,
      user_id: auth.user?.id,
      technicians_ids: dt.responsibles,
      unit_id: dt.property,
      product_model_id: dt.model,
      scheduled_at: dt.scheduled_at
        .hour(dt.time.split(":")[0])
        .minute(0)
        .format("YYYY-MM-DD HH:mm:00"),
      end_at: dt.end
        .hour(dt.time_end.split(":")[0])
        .minute(0)
        .format("YYYY-MM-DD HH:mm:00"),
    };
    if (dt.so_type === osTypes.REDUCER_MAINTENANCE) {
      serviceData.reducers_ids = dt.reducers_ids;
      serviceData.so_type = dt.so_type;
    }

    dispatch(asyncServiceOrdersActions.edit(id, serviceData, files))
      .then(() => {
        resetForm();
        setPropertyLocation(null);
        setResponsibles([]);
        navigate("/os/aberto");
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      so_type: "",
      reducers_ids: [], //array de ids
      pivot_id: undefined,
      company: "",
      property: "",
      client: "",
      family: "",
      model: "",
      issue: "",
      product: "",
      description: "",
      internal_observations: "",
      invoice: "",
      serial_number: "",
      service_type: "",
      time: "",
      scheduled_at: null,
      time_end: "",
      end: null,
      resale: "",
      technicians_origin: "",
      responsibles: [],
    },
    editSoValidationSchema,
    onSubmit: handleSubmit,
  });

  const clearStore = () => {
    dispatch(cleanSearch());
    dispatch({ type: CLEAN_COMPANIES_STORE });
    dispatch({ type: CLEAN_CUSTOMERS_STORE });
    dispatch({ type: CLEAN_PRODUCT_FAMILIES_STORE });
    dispatch({ type: CLEAN_ISSUES_STORE });
    dispatch({ type: CLEAN_PRODUCT_STORE });
    dispatch({ type: CLEAN_PRODUCT_MODEL_STORE });
    dispatch({ type: CLEAN_UNITS_STORE });
    dispatch({ type: CLEAN_SERVICE_ORDERS_STORE });
    dispatch({ type: CLEAN_FAMILY_ISSUES_STORE });
  };

  useEffect(() => {
    console.log("roda ou não");
    clearStore();

    dispatch(asyncCompaniesActions.getCompanies("", "", "", "", "", true));
    dispatch(
      asyncProductFamiliesActions.getProductFamilies("", "", "", "", "", true)
    );
    dispatch(asyncTaskTypesActions.get("", "", "", "", "", true));
    dispatch(getResales());

    return () => clearStore();
  }, [id]);

  useEffect(() => {
    dispatch(asyncServiceOrdersActions.get(id)).then(async (response) => {
      console.log(response);

      const so = response;

      setPreviewData({
        company: so.unit?.company?.name,
        property: so.unit?.address,
        client: `${so.responsible_user.profile.name} ${so.responsible_user.profile.last_name}`,
        family: so.product_model.product.product_family.name,
        product: so.product_model.product.name,
        model: so.product_model.name,
        issue: so.product_family_issues.map((issue) => issue.name + ", "),
        description: so.description,
        internal_observations: so.internal_observations,
        invoice: so.invoice,
        serial_number: so.serial_number,
        service_type: so.task.task_type.name,
        responsibles: so.external_user_technicians.map(
          (tec) => tec.user?.profile?.name
        ),
        scheduled_at: [
          dayjs(so.task.scheduled_at).format("DD/MM/YYYY"),
          dayjs(so.task.scheduled_at).format("HH:00"),
        ],
        endAt: [
          dayjs(so.task.scheduled_at).format("DD/MM/YYYY"),
          dayjs(so.task.scheduled_at).format("HH:00"),
        ],
      });

      const unit = dispatch(
        asyncUnitsActions.getUnitsFromCompany(
          "",
          "",
          "",
          "",
          "",
          true,
          so.unit?.company?.id
        )
      );

      const customer = dispatch(
        asyncCustomersActions.getCustomers(
          "",
          "",
          "",
          "",
          "",
          true,
          so.unit?.id
        )
      );

      const product = dispatch(
        asyncProductActions.getProduct(
          "",
          "",
          "",
          "",
          "",
          true,
          so.product_model.product.product_family_id
        )
      );
      const productModel = dispatch(
        asyncProductModelActions.getProductModels(
          "",
          "",
          "",
          "",
          "",
          true,
          so.product_model.product_id
        )
      );
      const familyIssues = dispatch(
        asyncFamilyIssuesActions.get(
          "",
          "",
          "",
          "",
          "",
          true,
          so.product_model.product.product_family_id
        )
      );

      const fetchPromises = [
        unit,
        customer,
        product,
        productModel,
        familyIssues,
        // reducersPromise,
      ];

      await Promise.all(fetchPromises).then((values) => {
        formik.setValues({
          so_type: so.so_type, // standard | reducer_MAINTENANCE
          reducers_ids: so.reducers.map((item) => item.id), //array de ids
          pivot_id: so.pivots[0].id,
          company: so.unit?.company?.id,
          property: so.unit?.id,
          client: so.responsible_user.id,
          family: so.product_model.product.product_family_id,
          product: so.product_model.product_id,
          model: so.product_model_id,
          issue: so.product_family_issues.map((issue) => issue.id),
          description: so.description,
          internal_observations: so.internal_observations,
          invoice: so.invoice,
          serial_number: so.serial_number,
          service_type: so.task.task_type_id,
          time: dayjs(so.task.scheduled_at).format("HH:00"),
          time_end: dayjs(so.task.end_at).format("HH:00"),
          scheduled_at: dayjs(so.task.scheduled_at),
          end: dayjs(so.task.end_at),

          //   scheduled_at: dt.scheduled_at
          //   .hour(dt.time.split(":")[0])
          //   .minute(0)
          //   .format("YYYY-MM-DD HH:mm:00"),
          // end_at: dt.end
          //   .hour(dt.time_end.split(":")[0])
          //   .minute(0)
          //   .format("YYYY-MM-DD HH:mm:00"),
        });
        if (so.reducers.lenght !== 0) {
          dispatch(setSelectedReducers(so.reducers));
        }
        dispatch(
          selectAnFamilyIssue(so.product_family_issues.map((issue) => issue))
        );
      });

      setPropertyLocation({
        lat: Number(so.latitude),
        lng: Number(so.longitude),
      });

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (auth.user && auth.user.role[0].name === ROLE_REVENDA) {
      formik.setFieldValue("technicians_origin", ROLE_REVENDA);
      formik.setFieldValue("resale", auth.user.profile.unit?.company_id);
    }
  }, []);

  useEffect(() => {
    if (formik.values.technicians_origin === KREBSFER) {
      dispatch(
        asyncUserTechniciansActions.getFree(
          auth.user.profile.unit?.company_id,
          formatDate(formik.values.scheduled_at, formik.values.time),
          formatDate(formik.values.end, formik.values.time_end)
        )
      );
    }
  }, [formik.values.technicians_origin]);

  useEffect(() => {
    if (
      previewData.scheduled_at[0] !== "" &&
      previewData.scheduled_at[1] !== "" &&
      previewData.endAt[0] !== "" &&
      previewData.endAt[1] !== ""
    ) {
      if (auth.user && auth.user.role[0].name === ROLE_REVENDA) {
        dispatch({ type: CLEAN_TECHNICIAN_STORE });

        dispatch(
          asyncUserTechniciansActions.getFree(
            auth.user.profile.unit?.company_id,
            formatDate(formik.values.scheduled_at, formik.values.time),
            formatDate(formik.values.end, formik.values.time_end)
          )
        );
      } else {
        dispatch({ type: CLEAN_TECHNICIAN_STORE });
        formik.setFieldValue("resale", "");
        formik.setFieldValue("technicians_origin", "");
      }
    }
  }, [previewData.scheduled_at, previewData.endAt]);

  const handlePreviewData = (field, value) => {
    setPreviewData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleChangeTechnicians = (e) => {
    setChangeTechnicians(e);

    if (e === false) {
      setShow(false);
      formik.setFieldValue("technicians_origin", "");
    }
  };

  return (
    <Div>
      <Stack>
        <Typography variant={"h2"} mb={3}>
          Editar ordem de serviço #{id}
        </Typography>
      </Stack>

      {serviceOrders.loading || loading ? (
        <ServiceOrdersSkeleton />
      ) : (
        <JumboCardQuick>
          <JumboContent>
            <Stack direction="row" flex={1}>
              <form
                autoComplete="off"
                style={{ display: "flex", flex: 1 }}
                onSubmit={formik.handleSubmit}
              >
                <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  {/* // aqui colocar o o if de role revenda */}

                  {auth.user.role[0].name !== ROLE_REVENDA && (
                    <>
                      <CardHeader title="Tipo de OS" sx={{ mb: -1, ml: -2 }} />
                      <Divider sx={{ mb: 2 }} />
                      <OsTypeField formik={formik} />
                      {formik.values.so_type ===
                        osTypes.REDUCER_MAINTENANCE && (
                        <>
                          <PivotField formik={formik} />
                          <ReducerField formik={formik} />
                        </>
                      )}
                    </>
                  )}
                  <CardHeader title="Cliente" sx={{ mb: -1, ml: -2 }} />

                  <Divider sx={{ mb: 2 }} />
                  <AutoCompleteInput
                    fieldName="company"
                    fieldLabel="Empresa"
                    formik={formik}
                    data={data.data}
                    placeHolder="Busque por uma empresa..."
                    dependentFields={["property", "client"]}
                    clearStores={[
                      { type: CLEAN_UNITS_STORE },
                      { type: CLEAN_CUSTOMERS_STORE },
                    ]}
                    handlePreviewData={handlePreviewData}
                    getNextFieldData={getUnitsFromCompany}
                    loading={companies.loading}
                    thunkLayout={SINGLE}
                  />

                  <MenuLocationSelectInput
                    dependsOn="company"
                    fieldName="property"
                    fieldLabel="Unidade"
                    formik={formik}
                    data={units.data}
                    placeHolder="Escolha a unidade..."
                    dependentFields={["client"]}
                    clearStores={[{ type: CLEAN_CUSTOMERS_STORE }]}
                    handlePreviewData={handlePreviewData}
                    setPropertyLocation={setPropertyLocation}
                    getNextFieldData={getCustomers}
                    loading={units.loading}
                  />

                  <AutoCompleteInput
                    dependsOn="property"
                    fieldName="client"
                    fieldLabel="Cliente"
                    formik={formik}
                    data={customers.data.data}
                    placeHolder="Escolha o cliente"
                    handlePreviewData={handlePreviewData}
                    subLevelData="profile"
                    loading={customers.loading}
                  />

                  <CardHeader title="Produto" sx={{ mb: -1, ml: -2 }} />

                  <Divider sx={{ mb: 2 }} />
                  <AutoCompleteInput
                    fieldName="family"
                    fieldLabel="Familia"
                    formik={formik}
                    data={productFamilies.data.data}
                    placeHolder="Busque por uma família..."
                    dependentFields={["product", "model", "issue"]}
                    clearStores={[
                      { type: CLEAN_PRODUCT_STORE },
                      { type: CLEAN_PRODUCT_MODEL_STORE },
                      { type: CLEAN_FAMILY_ISSUES_STORE },
                    ]}
                    handlePreviewData={handlePreviewData}
                    getNextFieldData={getProduct}
                    loading={productFamilies.loading}
                    thunkLayout={MULTIPLE}
                  />

                  <AutoCompleteInput
                    fieldName="product"
                    fieldLabel="Produtos"
                    formik={formik}
                    data={product.data.data}
                    placeHolder="Busque por uma família..."
                    dependentFields={["model", "issue"]}
                    clearStores={[
                      { type: CLEAN_PRODUCT_MODEL_STORE },
                      { type: CLEAN_FAMILY_ISSUES_STORE },
                    ]}
                    handlePreviewData={handlePreviewData}
                    getNextFieldData={getProductModels}
                    dependsOn="family"
                    loading={product.loading}
                    thunkLayout={MULTIPLE}
                  />

                  <AutoCompleteInput
                    fieldName="model"
                    fieldLabel="Modelo"
                    formik={formik}
                    data={productModels.data.data}
                    a
                    placeHolder="Busque por uma Modelo..."
                    dependentFields={["issue"]}
                    clearStores={[{ type: CLEAN_FAMILY_ISSUES_STORE }]}
                    handlePreviewData={handlePreviewData}
                    getNextFieldData={asyncFamilyIssuesActions.get}
                    dependsOn="product"
                    loading={productModels.loading}
                    thunkLayout={MULTIPLE}
                    referField={formik.values.family}
                  />

                  <MultiSelectInput
                    fieldName="issue"
                    fieldLabel="Ocorrências"
                    formik={formik}
                    data={familyIssues.data.data}
                    placeHolder="Busque por uma problema..."
                    dependentFields={["modelo"]}
                    handlePreviewData={handlePreviewData}
                    dataValue={familyIssues.selectedIssues}
                    handleDataValue={selectAnFamilyIssue}
                    dependsOn="product"
                    loading={familyIssues.loading}
                  />

                  <CardHeader title="Serviço" sx={{ mb: -1, ml: -2 }} />

                  <Divider sx={{ mb: 2 }} />

                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      multiline
                      id="description"
                      name="description"
                      label="Descrição da Ordem de Serviço"
                      rows={2}
                      value={formik.values.description}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePreviewData("description", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      multiline
                      id="internal_observations"
                      name="internal_observations"
                      label="Observações Internas"
                      rows={2}
                      value={formik.values.internal_observations}
                      error={
                        formik.touched.internal_observations &&
                        Boolean(formik.errors.internal_observations)
                      }
                      helperText={
                        formik.touched.internal_observations &&
                        formik.errors.internal_observations
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePreviewData(
                          "internal_observations",
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      id="invoice"
                      name="invoice"
                      label="Nota Fiscal"
                      value={formik.values.invoice}
                      error={
                        formik.touched.invoice && Boolean(formik.errors.invoice)
                      }
                      helperText={
                        formik.touched.invoice && formik.errors.invoice
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePreviewData("invoice", e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      id="serial_number"
                      name="serial_number"
                      label="Número de Série"
                      value={formik.values.serial_number}
                      error={
                        formik.touched.serial_number &&
                        Boolean(formik.errors.serial_number)
                      }
                      helperText={
                        formik.touched.serial_number &&
                        formik.errors.serial_number
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        handlePreviewData("serial_number", e.target.value);
                      }}
                    />
                  </FormControl>

                  <AutoCompleteInput
                    fieldName="service_type"
                    fieldLabel="Tipo de serviço a ser realizado"
                    formik={formik}
                    data={taskTypes.data.data}
                    placeHolder="Busque pelo tipo de serviço..."
                    handlePreviewData={handlePreviewData}
                    loading={taskTypes.loading}
                    thunkLayout={MULTIPLE}
                  />

                  <FormControl sx={{ mb: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-testid={"input-date"}
                        disablePast
                        id="date"
                        name="date"
                        label="Início"
                        format="DD/MM/YYYY"
                        minDate={moment().utc()}
                        onAccept={(newValue) => {
                          if (newValue) {
                            formik.setFieldValue(
                              "scheduled_at",
                              newValue,
                              true
                            );
                            const date = newValue.format("DD/MM/YYYY");

                            setPreviewData((prev) => ({
                              ...prev,
                              scheduled_at: [date, prev.scheduled_at[1]],
                            }));

                            if (formik.values["end"]) {
                              const end = formik.values["end"];

                              const diffEnd = end.diff(newValue, "d");

                              if (diffEnd < 0) {
                                formik.setFieldValue("end", newValue);

                                setPreviewData((prev) => ({
                                  ...prev,
                                  endAt: [
                                    newValue.format("DD/MM/YYYY"),
                                    prev.endAt[1],
                                  ],
                                }));
                              }
                            }
                          }
                        }}
                        onChange={(newValue) => {
                          if (
                            newValue &&
                            newValue.format("DD/MM/YYYY") != "Invalid Date"
                          ) {
                            formik.setFieldValue(
                              "scheduled_at",
                              newValue,
                              true
                            );
                            const date = newValue.format("DD/MM/YYYY");

                            setPreviewData({
                              ...previewData,
                              scheduled_at: [date, previewData.scheduled_at[1]],
                            });
                          }
                        }}
                        value={formik.values.scheduled_at}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.scheduled_at &&
                              Boolean(formik.errors.scheduled_at),
                            helperText:
                              formik.touched.scheduled_at &&
                              formik.errors.scheduled_at,
                            onBlur: () => {
                              if (formik.values["scheduled_at"]) {
                                const today = dayjs().startOf("day");
                                const date = formik.values["scheduled_at"];
                                const diff = date.diff(today, "d");

                                setPreviewData((prev) => ({
                                  ...prev,
                                  scheduled_at: [
                                    date.format("DD/MM/YYYY"),
                                    prev.scheduled_at[1],
                                  ],
                                }));

                                if (diff < 0) {
                                  formik.setFieldValue("scheduled_at", today);

                                  setPreviewData((prev) => ({
                                    ...prev,
                                    scheduled_at: [
                                      today.format("DD/MM/YYYY"),
                                      prev.scheduled_at[1],
                                    ],
                                  }));
                                }

                                if (formik.values["end"]) {
                                  const end = formik.values["end"];

                                  const diffEnd = end.diff(date, "d");

                                  if (diffEnd < 0) {
                                    formik.setFieldValue("end", date);

                                    setPreviewData((prev) => ({
                                      ...prev,
                                      endAt: [
                                        date.format("DD/MM/YYYY"),
                                        prev.endAt[1],
                                      ],
                                    }));
                                  }
                                }
                              }
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      data-testid={"input-time"}
                      select
                      disabled={!previewData.scheduled_at[0]}
                      id="time"
                      name="time"
                      label="Horário Início"
                      value={formik.values.time}
                      error={formik.touched.time && Boolean(formik.errors.time)}
                      helperText={formik.touched.time && formik.errors.time}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("time_end", "");
                        setPreviewData({
                          ...previewData,
                          scheduled_at: [
                            previewData.scheduled_at[0],
                            e.target.value,
                          ],
                        });
                      }}
                    >
                      {freeTimes.map((time, index) => (
                        <MenuItem
                          key={index}
                          data-testid={`select-option-time-${index}`}
                          value={time}
                        >
                          {time}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-testid={"input-end"}
                        disablePast
                        disabled={!previewData.scheduled_at[1]}
                        id="end"
                        name="end"
                        label="Final"
                        format="DD/MM/YYYY"
                        minDate={formik.values.scheduled_at}
                        value={formik.values.end}
                        onChange={(newValue) => {
                          if (
                            newValue &&
                            newValue.format("DD/MM/YYYY") != "Invalid Date"
                          ) {
                            formik.setFieldValue("end", newValue, true);
                            const date = newValue.format("DD/MM/YYYY");
                            formik.setFieldValue("time_end", "");
                            setPreviewData((prev) => ({
                              ...prev,
                              endAt: [date, previewData.endAt[1]],
                            }));
                          }
                        }}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.end && Boolean(formik.errors.end),
                            helperText: formik.touched.end && formik.errors.end,
                            onBlur: () => {
                              if (formik.values["end"]) {
                                const start = formik.values["scheduled_at"];
                                const end = formik.values["end"];

                                const diff = end.diff(start, "d");

                                if (diff < 0) {
                                  formik.setFieldValue("end", start);
                                  setPreviewData({
                                    ...previewData,
                                    endAt: [
                                      start.format("DD/MM/YYYY"),
                                      previewData.endAt[1],
                                    ],
                                  });
                                }
                              }
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <TextField
                      fullWidth
                      data-testid={"input-time_end"}
                      select
                      disabled={!previewData.endAt[0]}
                      id="time_end"
                      name="time_end"
                      label="Horário Final"
                      value={formik.values.time_end}
                      error={
                        formik.touched.time_end &&
                        Boolean(formik.errors.time_end)
                      }
                      helperText={
                        formik.touched.time_end && formik.errors.time_end
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        setPreviewData({
                          ...previewData,
                          endAt: [previewData.endAt[0], e.target.value],
                        });
                      }}
                    >
                      {freeTimes
                        .filter((time) => time >= previewData.scheduled_at[1])
                        .map((freeTime, index) => (
                          <MenuItem
                            key={index}
                            data-testid={`select-option-time_end-${index}`}
                            value={freeTime}
                          >
                            {freeTime}
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="changeTechnicians"
                          checked={changeTechnicians}
                          onChange={(e) =>
                            handleChangeTechnicians(e.target.checked)
                          }
                        />
                      }
                      label="Alterar técnicos responsáveis?"
                    />
                  </FormControl>

                  {changeTechnicians && (
                    <>
                      {auth.user && auth.user.role[0].name !== ROLE_REVENDA && (
                        <MenuSelectInput
                          fieldName="technicians_origin"
                          fieldLabel="Origem dos Técnicos"
                          dependentFields={["responsibles"]}
                          dependsOn={"time_end"}
                          formik={formik}
                          data={ORIGIN_SELECT_DATA}
                          clearStores={[{ type: CLEAN_TECHNICIAN_STORE }]}
                          placeHolder="Escolha a origem dos técnicos..."
                          handlePreviewData={handlePreviewData}
                        />
                      )}
                      {auth.user &&
                        auth.user.role[0].name !== ROLE_REVENDA &&
                        formik.values.technicians_origin === ROLE_REVENDA && (
                          <AutoCompleteInput
                            fieldName="resale"
                            fieldLabel="Revenda"
                            formik={formik}
                            data={resales.data}
                            placeHolder="Busque por uma revenda..."
                            clearStores={[{ type: CLEAN_TECHNICIAN_STORE }]}
                            handlePreviewData={handlePreviewData}
                            loading={resales.loading}
                            getNextFieldData={
                              asyncUserTechniciansActions.getFree
                            }
                            thunkLayout={RESELER_THUNK}
                          />
                        )}

                      {(formik.values.resale ||
                        formik.values.technicians_origin === KREBSFER ||
                        auth.user.role[0].name === ROLE_REVENDA) && (
                        <FormControl sx={{ mb: 1 }}>
                          <MultiSelectInput
                            fieldName="responsibles"
                            fieldLabel="Técnicos responsáveis"
                            dependsOn="resale"
                            formik={formik}
                            data={technicians.data}
                            placeHolder="Busque por um responsável..."
                            handlePreviewData={handlePreviewData}
                            loading={technicians.loading}
                            subLevelData="profile"
                            dataValue={technicians.selectedTechnician}
                            handleDataValue={selectTechnician}
                          />
                        </FormControl>
                      )}
                    </>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showImagePicker}
                          onChange={(e) => setShowImagePicker(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Deseja inserir imagens?"
                    />
                  </FormGroup>

                  {showImagePicker && (
                    <FormControl>
                      <InputLabel>Imagens do Equipamento</InputLabel>
                      <DzPreviews selectFiles={setImages} image />
                    </FormControl>
                  )}

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showVideoPicker}
                          onChange={(e) => setShowVideoPicker(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Deseja inserir vídeos?"
                    />
                  </FormGroup>

                  {showVideoPicker && (
                    <FormControl>
                      <InputLabel>Vídeos do Equipamento</InputLabel>
                      <DzPreviews selectFiles={setVideos} video />
                    </FormControl>
                  )}

                  <Button
                    data-testid="btn-submit"
                    variant="contained"
                    color="error"
                    type="submit"
                    sx={{
                      mt: 5,
                    }}
                  >
                    Salvar
                  </Button>
                </Div>
              </form>

              <Stack direction="column" flex={1} p={5}>
                {propertyLocation ? (
                  <SimpleMap location={propertyLocation} />
                ) : (
                  <Stack width="100%" height="400px" alignItems="center">
                    <Lottie
                      animationData={locationAnimation}
                      loop={true}
                      style={{
                        height: 350,
                      }}
                    />
                    <ListItemText primary="Selecione uma propriedade" />
                  </Stack>
                )}

                <Div>
                  <CardHeader
                    title="Dados da Ordem de Serviço"
                    sx={{ mb: -1, ml: -2 }}
                  />
                  <Divider sx={{ mb: 2 }} />

                  <List disablePadding sx={{ width: "100%" }}>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        width: "80%",
                        display: "grid",
                        gridRow: "1",
                        gridTemplateColumns: "repeat(2,1fr)",
                        rowGap: 2,
                        p: (theme) => theme.spacing(1.25, 1),
                      }}
                    >
                      <ListItemText primary="Empresa" />
                      <ListItemText secondary={previewData.company} />
                      <ListItemText primary="Unidade" />
                      <ListItemText secondary={previewData.property} />
                      <ListItemText primary="Cliente" />
                      <ListItemText secondary={previewData.client} />
                      <ListItemText primary="Família" />
                      <ListItemText secondary={previewData.family} />
                      <ListItemText primary="Produto" />
                      <ListItemText secondary={previewData.product} />
                      <ListItemText primary="Modelo" />
                      <ListItemText secondary={previewData.model} />
                      <ListItemText primary="Ocorrências" />
                      <ListItemText secondary={previewData.issue} />
                      <ListItemText primary="Descrição" />
                      <ListItemText secondary={previewData.description} />
                      <ListItemText primary="Observações Internas" />
                      <ListItemText
                        secondary={previewData.internal_observations}
                      />
                      <ListItemText primary="Nota Fiscal" />
                      <ListItemText secondary={previewData.invoice} />
                      <ListItemText primary="Número de Série" />
                      <ListItemText secondary={previewData.serial_number} />
                      <ListItemText primary="Tipo de Serviço" />
                      <ListItemText secondary={previewData.service_type} />
                      <ListItemText primary="Técnicos responsáveis" />
                      <ListItemText secondary={previewData.responsibles} />
                      <ListItemText primary="Agendado para" />
                      <ListItemText
                        secondary={`${
                          previewData.scheduled_at[0] != "" &&
                          !previewData.scheduled_at[0].includes("inválida")
                            ? previewData.scheduled_at[0]
                            : ""
                        } ${previewData.scheduled_at[1]} ${
                          previewData.endAt[0] != "" &&
                          !previewData.endAt[0].includes("inválida")
                            ? `até ${previewData.endAt[0]} ${previewData.endAt[1]}`
                            : ""
                        }`}
                      />
                    </ListItem>
                  </List>
                </Div>
              </Stack>
            </Stack>
          </JumboContent>
        </JumboCardQuick>
      )}
    </Div>
  );
}
