import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const companyApiSlice = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchCompanies: builder.query({
        query() {
          return "companies/has-pivots";
        },
      }),
      fetchCompaniesForSelect: builder.query({
        query() {
          return "companies";
        },
      }),
      fetchUnitsForSelect: builder.query({
        query(company_id) {
          return `units?company_id=${company_id}`;
        },
      }),
    };
  },
});

export const {
  useFetchCompaniesQuery,
  useFetchCompaniesForSelectQuery,
  useFetchUnitsForSelectQuery,
} = companyApiSlice;
