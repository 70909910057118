import React from 'react';
import { useParams } from "react-router-dom";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
/* import { contactService } from "../../../../../services/contact-services"; */
import ContactItem from "./ContactItem";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import BulkActions from "./BulkActions";
import { Card } from "@mui/material";
import JumboSearch from "@jumbo/components/JumboSearch";
import useContactsApp from "../../hooks/useContactsApp";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { getAssetPath } from 'app/utils/appHelpers';
import { ASSET_AVATARS } from 'app/utils/constants/paths';
import Div from '@jumbo/shared/Div';
import Units from 'app/pages/Companies/Units';
import ChamadosTecnicos from 'app/pages/ChamadosTecnicos';
import Tickets from 'app/pages/Tickets';

const ContactsList = () => {
    const params = useParams();
    const listRef = React.useRef();
    const { refreshContactsList, setContactsListRefresh, setSelectedContacts, field, company } = useContactsApp();
    const [view, setView] = React.useState("list");

    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "contacts",
        queryParams: { category: params.category, id: params.id },
        countKey: "count",
        dataKey: "contacts",
    });

    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: { ...state.queryParams, category: params.category, id: params.id }
        }))
    }, [params]);

    const renderContact = React.useCallback((contact) => {
        return (<ContactItem contact={contact} view={view} />)
    }, [view]);

    React.useEffect(() => {
        if (refreshContactsList) {
            listRef.current.refresh();
            setContactsListRefresh(false);
        }
    }, [refreshContactsList]);

    const handleOnChange = React.useCallback((keywords) => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                keywords: keywords,
            }
        }))
    }, []);

    const companyFieldService = () => {
        switch (field) {
            case "unit":
                return company.units;
            case "tickets":
                const tickets = [];

                company.units.map(unit => {
                    unit.tickets.map(ticket => {
                        tickets.push({
                            ...ticket,
                            unit_name: unit.name
                        })
                    })
                })

                return tickets;
            case "serviceOrders":
                const serviceOrders = [];

                company.units.map(unit => {
                    unit.service_orders.map(so => {
                        serviceOrders.push({
                            ...so,
                            unit_name: unit.name
                        })
                    })
                })

                return serviceOrders;
            default:
                return [];
        }
    };

    React.useEffect(() => {
        companyFieldService();
        /* setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                field
            }
        })) */
    }, [field]);

    return (
        <>
            { field == "unit" && <Units companyId={company.id} /> }

            { field == "tickets" && <Tickets companyId={company.id} /> }

            { field == "serviceOrders" && <ChamadosTecnicos companyId={company.id} /> }

            {/* <JumboRqList
                ref={listRef}
                wrapperComponent={Card}
                service={companyFieldService}
                primaryKey={"id"}
                queryOptions={queryOptions}
                itemsPerPage={8}
                itemsPerPageOptions={[8, 15, 20]}
                renderItem={renderContact}
                componentElement={"div"}
                sx={view === 'grid' && { p: theme => theme.spacing(1, 3, 3) }}
                wrapperSx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                onSelectionChange={setSelectedContacts}
                view={view}
            /> */}
        </>
    );
};

export default ContactsList;
