import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import reducers from "../reducers"; // Adjust the import path as necessary
import { companyApiSlice } from "app/pages/Pivos/features/Company/company-slice";
import { pivoApiSlice } from "app/pages/Pivos/features/pivos/pivos-slice";
import { reducerApiSlice } from "app/pages/Pivos/features/redutores/reducer-slice";
import { serviceOrderFieldsSlice } from "app/pages/ServiceOrders/features/serviceOrderFields-slice";

// Create browser history
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Specify any reducers you want to persist here
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

function configureAppStore(initialState = {}) {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types in the serializable check middleware
          ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        },
      })
        .concat(routeMiddleware, thunk)
        .concat(companyApiSlice.middleware)
        .concat(reducerApiSlice.middleware)
        .concat(pivoApiSlice.middleware)
        .concat(serviceOrderFieldsSlice.middleware),

    preloadedState: initialState,
  });

  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../reducers", () => {
      const newRootReducer = require("../reducers").default;
      store.replaceReducer(
        persistReducer(persistConfig, newRootReducer(history))
      );
    });
  }

  return { store, persistor };
}

export const { store, persistor } = configureAppStore();
